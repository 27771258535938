.c-article {
  @include clearfix;
  position: relative;
  background: #fff;
  max-width: $readable-content-width;
  margin: 0 auto;
  padding-bottom: $pre-footer__padding;

  &__summary {
    @extend .o-text--summary;
    max-width: 28em;
    margin: 0 auto;
    padding: 0 $block__gutter--small;

    &::after {
      @extend hr;
      content: '';
      display: block;
      margin-bottom: 4rem;
    }
  }

  &__body {
    clear: both;
  }

  @include breakpoint(medium-large) {
    margin-top: $page-content__margin--top;
  }

  @each $breakpoint, $overlap in $post-banner__overlap {
    @include breakpoint($breakpoint) {
      &--after-banner {
        margin-top: -$overlap;
      }
    }
  }
}
