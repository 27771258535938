// Utilities
// - - - - - - - - - - - - - - - -

@mixin absolute-center() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@mixin responsive-column($count, $breakpoint: medium) {
  @if (round($count) != $count) {
    @error '$count must be a number greater than 0.';
  }

  @include breakpoint($breakpoint) {
    width: percentage(1/$count);

    &:nth-child(#{$count}n + 1) {
      clear: both;
    }
  }
}

@mixin dark-link() {
  color: lighten($anchor-color, 20%);

  &:hover, &:active, &:focus {
    color: lighten($anchor-color, 35%);
  }
}

// Components
// - - - - - - - - - - - - - - - -

@mixin accent-stripe($size, $color: $primary-color) {
  content: '';
  display: block;
  background: $color;

  @if $size == large {
    width: 4rem;
    height: $stripe__width * 1.5;
  } @else if $size == small {
    width: 3rem;
    height: $stripe__width * 0.5;
  } @else {
    width: 3rem;
    height: $stripe__width;
  }
}

@mixin contributor-icon($size, $override-background: true) {
  $outline-size-difference: 15%;
  $thumb-size: $size * ((100% - $outline-size-difference)/100%);
  $outline-size: $thumb-size + ($thumb-size * ($outline-size-difference/100%));
  $thumb-outline-offset: ($outline-size - $thumb-size);
  $outline-border-color: $red;
  $outline-border-width: 2px;
  position: relative;
  width: $thumb-size;
  height: $thumb-size;
  margin: $thumb-outline-offset auto $thumb-outline-offset;
  @if ($override-background) {
    background: $unloaded-image-color no-repeat center;
    background-size: cover;
  }
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: -$outline-size-difference/2;
    left: -$outline-size-difference/2;
    width: $outline-size;
    height: $outline-size;
    border: $outline-border-width solid $outline-border-color;
    border-radius: 50%;
  }
}

@mixin more-link() {
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;

  &::after {
    content: '\25b8';
    margin-left: 0.3rem;
  }
}

@mixin ghost-button($color: $primary-color, $text-color: $white, $hover-color: false) {
  @if $hover-color == false {
    $hover-color: $color;
  }
  display: inline-block;
  padding: 0.8rem 1em;
  border: 1px solid $color;
  color: $color;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover, &:active, &:focus {
    background: $hover-color;
    border-color: $hover-color;
    color: $text-color;
  }
}

@mixin flat-button($color: $primary-color, $text-color: $white) {
  @include ghost-button($color, $text-color, darken($color, 10));
  background: $color;
  color: $text-color;
}
