$circle-size: 4rem;
$circle-color: $body-font-color;
$circle-width: 1px;
$letter-circle-ratio: 1.4;
$letter-visual-adjustment: 0.97;
$circle-offset: 0.4;

@mixin drop-cap-letter-body($offset-x: 0, $offset-y: 0) {
  text-shadow: ($circle-size * $offset-x) ($circle-size * $offset-y) 0 $body-font-color;
}

.c-drop-caps {
  margin-top: ($circle-size * $circle-offset) + 1.3rem;

  &__letter {
    @include drop-cap-letter-body;
    float: left;
    display: block;
    font-weight: bold;
    font-style: normal;
    height: $circle-size;
    width: $circle-size;
    margin-top: (-$circle-size * $circle-offset);
    margin-right: 0.7rem;
    font-size: $circle-size * (1/$letter-circle-ratio);
    line-height: $letter-circle-ratio * $letter-visual-adjustment;
    text-align: center;
    text-transform: uppercase;
    color: transparent;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $circle-width $circle-color;
  }
}

// Adjust letter positions for custom font
@include fonts-loaded {
  @each $letter, $x-offset, $y-offset in $drop-cap-letter-offset {
    .c-drop-caps__letter[data-character="#{$letter}"] {
      @include drop-cap-letter-body($x-offset, $y-offset);
    }
  }
}
