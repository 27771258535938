@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr, .c-page-header__description::after, .c-article__summary::after {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a, .c-pull-quote a > cite {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active, .c-pull-quote a > cite:active,
a:hover,
.c-pull-quote a > cite:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&medium-large=50em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #777;
  font-family: Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.2;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
.o-home-row__header,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2,
.o-home-row__header, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .o-home-row__header small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #bfbfbf; }

h1, .h1 {
  font-size: 2.625rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

h2, .o-home-row__header, .h2 {
  font-size: 2.34rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

h3, .h3 {
  font-size: 1.7991rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

h4, .h4 {
  font-size: 1.414rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

h5, .h5 {
  font-size: 1rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

h6, .h6 {
  font-size: 0.707rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.75rem; }

@media print, screen and (min-width: 64em) {
  h1, .h1 {
    font-size: 3.5rem; }
  h2, .o-home-row__header, .h2 {
    font-size: 2.6rem; }
  h3, .h3 {
    font-size: 1.999rem; }
  h4, .h4 {
    font-size: 1.414rem; }
  h5, .h5 {
    font-size: 1rem; }
  h6, .h6 {
    font-size: 0.707rem; } }

a, .c-pull-quote a > cite {
  line-height: inherit;
  color: #1e9abd;
  text-decoration: none;
  cursor: pointer; }
  a:hover, .c-pull-quote a > cite:hover, a:focus, .c-pull-quote a > cite:focus {
    color: #08466c; }
  a img, .c-pull-quote a > cite img {
    border: 0; }

hr, .c-page-header__description::after, .c-article__summary::after {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #bfbfbf;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0;
  border-left: none; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #333; }

cite {
  display: block;
  font-size: 1em;
  color: #333; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #bfbfbf;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #333; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a, .c-pull-quote a > cite,
  a:visited,
  .c-pull-quote a > cite:visited {
    text-decoration: underline; }
  a[href]:after, .c-pull-quote a > cite[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after, .ir .c-pull-quote a > cite:after, .c-pull-quote .ir a > cite:after,
  a[href^='javascript:']:after,
  .c-pull-quote a > cite[href^='javascript:']:after,
  a[href^='#']:after,
  .c-pull-quote a > cite[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  .o-home-row__header,
  h3 {
    orphans: 3;
    widows: 3; }
  h2, .o-home-row__header,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.wf-opensans-n4-active.wf-opensans-i4-active body,
.wf-opensans-n4-active.wf-opensans-i4-active h1, .wf-opensans-n4-active.wf-opensans-i4-active h2, .wf-opensans-n4-active.wf-opensans-i4-active .o-home-row__header, .wf-opensans-n4-active.wf-opensans-i4-active h3, .wf-opensans-n4-active.wf-opensans-i4-active h4, .wf-opensans-n4-active.wf-opensans-i4-active h5, .wf-opensans-n4-active.wf-opensans-i4-active h6,
.wf-preloaded body,
.wf-preloaded h1,
.wf-preloaded h2,
.wf-preloaded .o-home-row__header,
.wf-preloaded h3,
.wf-preloaded h4,
.wf-preloaded h5,
.wf-preloaded h6 {
  font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif; }

.o-edit-link {
  z-index: 999;
  position: fixed;
  display: block;
  top: 2.88rem;
  right: 0;
  padding: 0.7rem 0;
  width: 3.125rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase; }
  .o-edit-link:hover, .o-edit-link:active, .o-edit-link:focus {
    background: #333;
    color: #fff; }
  @media print, screen and (min-width: 50em) {
    .o-edit-link {
      top: 0; } }

blockquote {
  padding: 0.5rem 0 0.5rem 1.5rem;
  font-style: italic; }
  blockquote em {
    font-style: normal; }
  blockquote cite {
    margin-top: 1rem;
    font-style: normal;
    font-size: 80%; }
    blockquote cite::before {
      content: '- '; }

hr, .c-page-header__description::after, .c-article__summary::after {
  width: 16rem;
  max-width: 75%;
  margin: 4rem auto;
  border: 0 dotted #e6e6e6;
  border-bottom-width: 2px; }

.c-drop-caps {
  margin-top: 2.9rem; }
  .c-drop-caps__letter {
    text-shadow: 0rem 0rem 0 #333;
    float: left;
    display: block;
    font-weight: bold;
    font-style: normal;
    height: 4rem;
    width: 4rem;
    margin-top: -1.6rem;
    margin-right: 0.7rem;
    font-size: 2.85714rem;
    line-height: 1.358;
    text-align: center;
    text-transform: uppercase;
    color: transparent;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #333; }

.wf-opensans-n4-active.wf-opensans-i4-active .c-drop-caps__letter[data-character="C"],
.wf-preloaded .c-drop-caps__letter[data-character="C"] {
  text-shadow: -0.16rem 0rem 0 #333; }

.wf-opensans-n4-active.wf-opensans-i4-active .c-drop-caps__letter[data-character="D"],
.wf-preloaded .c-drop-caps__letter[data-character="D"] {
  text-shadow: 0.08rem 0rem 0 #333; }

.wf-opensans-n4-active.wf-opensans-i4-active .c-drop-caps__letter[data-character="J"],
.wf-preloaded .c-drop-caps__letter[data-character="J"] {
  text-shadow: 0.12rem -0.28rem 0 #333; }

.wf-opensans-n4-active.wf-opensans-i4-active .c-drop-caps__letter[data-character="Q"],
.wf-preloaded .c-drop-caps__letter[data-character="Q"] {
  text-shadow: -0.04rem 0rem 0 #333; }

.o-text--summary, .c-article__summary {
  margin-bottom: 2rem;
  font-size: 1.414rem;
  line-height: 1.4;
  font-style: italic;
  text-align: center; }
  .o-text--summary em, .c-article__summary em {
    font-style: normal; }

.c-page::before, .c-page::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.c-page::after {
  clear: both; }

.c-page__content {
  position: relative;
  margin-top: 2.88rem; }

.c-page__footer {
  position: relative;
  max-width: 62rem;
  margin: -9.5rem auto 0;
  padding: 2rem 1rem 1rem;
  background: #333;
  font-size: 80%;
  color: #bfbfbf; }
  .c-page__footer::before, .c-page__footer::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-page__footer::after {
    clear: both; }
  .c-page__footer::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    background: #21abd2;
    width: 4rem;
    height: 7.5px; }
  .c-page__footer--after-post {
    max-width: 52rem;
    margin-top: 0; }
  .c-page__footer p {
    line-height: 1.3; }
  .c-page__footer a, .c-page__footer .c-pull-quote a > cite, .c-pull-quote .c-page__footer a > cite {
    color: #5cc7e5; }
    .c-page__footer a:hover, .c-page__footer .c-pull-quote a > cite:hover, .c-pull-quote .c-page__footer a > cite:hover, .c-page__footer a:active, .c-page__footer .c-pull-quote a > cite:active, .c-pull-quote .c-page__footer a > cite:active, .c-page__footer a:focus, .c-page__footer .c-pull-quote a > cite:focus, .c-pull-quote .c-page__footer a > cite:focus {
      color: #9edef0; }
  .c-page__footer__column {
    overflow: hidden; }
  .c-page__footer__logo {
    height: 2.5rem;
    margin-bottom: 2rem;
    background: url("/assets/magazine/svg/logo.svg") center no-repeat;
    background-size: contain; }
  .c-page__footer__header {
    margin-bottom: 0;
    color: inherit;
    font-size: 80%;
    line-height: 1.3;
    text-transform: uppercase; }

@media print, screen and (min-width: 40em) {
  .c-page__content__wrapper {
    position: relative;
    padding: 0 1rem; }
    .c-page__content__wrapper::before, .c-page__content__wrapper::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .c-page__content__wrapper::after {
      clear: both; }
    .c-page__content__wrapper--after-banner {
      background: linear-gradient(to bottom, #6a6a6a, rgba(119, 119, 119, 0)) no-repeat;
      background-size: 100% 25rem; }
  .c-page__footer {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1.3; }
    .c-page__footer__column {
      float: left;
      width: 30%;
      padding-left: 1rem;
      padding-right: 1rem; }
    .c-page__footer__logo {
      float: left;
      width: calc(40% - 1rem);
      margin: 2rem 1rem 0 0; } }

@media print, screen and (min-width: 50em) {
  .c-page__navbar {
    position: fixed;
    width: 16.875rem;
    height: 100vh;
    background: #f5f5f5;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .c-page__content {
    position: relative;
    width: calc(100% - 16.875rem);
    min-height: 100vh;
    margin-top: 0;
    margin-left: 16.875rem; } }

.c-navbar {
  margin: 0;
  background: #f5f5f5;
  list-style: none; }
  @media print, screen and (min-width: 50em) {
    .c-navbar {
      padding-bottom: 1rem; } }
  .c-navbar__entry {
    position: relative;
    margin: 0;
    padding: 0;
    border-style: dotted;
    border-color: #e6e6e6;
    border-width: 1px 0; }
    .c-navbar__entry + .c-navbar__entry {
      border-top-width: 0; }
    .c-navbar__entry--no-border {
      border: 0; }
    .c-navbar__entry--collapsible {
      display: none; }
    @media print, screen and (min-width: 50em) {
      .c-navbar__entry--collapsible {
        display: block; } }
  .c-navbar__logo {
    height: 3rem;
    background: #8a8a8a;
    margin: 1.3rem 2.7rem 1rem;
    background: url("/assets/magazine/svg/logo.svg") center no-repeat;
    background-size: contain; }
    .c-navbar__logo__text {
      display: none; }
  .c-navbar__input {
    display: block;
    appearance: none !important;
    box-sizing: border-box !important;
    width: 100%;
    max-width: 100%;
    padding-left: 2.7rem;
    border: none;
    font-size: 1rem;
    line-height: 2.4rem;
    border-radius: 0; }
  .c-navbar__search::before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
    margin: auto;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background: url("/assets/magazine/svg/search.svg") center no-repeat;
    background-size: contain;
    opacity: 0.5; }
  .c-navbar__link {
    display: block;
    padding-left: 0.75rem;
    color: #8a8a8a;
    font-size: 1rem;
    line-height: 2.4rem;
    text-transform: uppercase; }
    .c-navbar__link:hover, .c-navbar__link:active, .c-navbar__link:focus {
      background: #e6e6e6;
      color: #333; }
    .c-navbar__link__text::before {
      content: '';
      position: relative;
      display: inline-block;
      top: 0.15rem;
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.75rem;
      background: center no-repeat;
      background-size: contain; }
    .c-navbar__link--magazine__text::before {
      background-image: url("/assets/magazine/svg/magazine.svg"); }
    .c-navbar__link--library__text::before {
      background-image: url("/assets/magazine/svg/library.svg"); }
    .c-navbar__link--contributors__text::before {
      background-image: url("/assets/magazine/svg/contributors.svg"); }
    .c-navbar__link--about__text::before {
      background-image: url("/assets/magazine/svg/about.svg"); }
  .c-navbar__categories {
    width: 100%; }
    .c-navbar__categories::before, .c-navbar__categories::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .c-navbar__categories::after {
      clear: both; }
    .c-navbar__categories__link {
      display: block;
      float: left;
      width: 33.33333%;
      color: #fff;
      font-size: 0.85rem;
      line-height: 2.4rem;
      text-align: center;
      text-transform: uppercase; }
      .c-navbar__categories__link:hover, .c-navbar__categories__link:active, .c-navbar__categories__link:focus {
        color: #fff; }
      .c-navbar__categories__link--vision {
        background: #08466c; }
        .c-navbar__categories__link--vision:hover, .c-navbar__categories__link--vision:active, .c-navbar__categories__link--vision:focus {
          background: #04273d; }
      .c-navbar__categories__link--story {
        background: #21abd2; }
        .c-navbar__categories__link--story:hover, .c-navbar__categories__link--story:active, .c-navbar__categories__link--story:focus {
          background: #1a87a6; }
      .c-navbar__categories__link--teaching {
        background: #fdbf52; }
        .c-navbar__categories__link--teaching:hover, .c-navbar__categories__link--teaching:active, .c-navbar__categories__link--teaching:focus {
          background: #fcac20; }
      .c-navbar__categories__link--contributor {
        background: #ec6351; }
        .c-navbar__categories__link--contributor:hover, .c-navbar__categories__link--contributor:active, .c-navbar__categories__link--contributor:focus {
          background: #e73a23; }
      .c-navbar__categories__link--collection {
        background: #21abd2; }
        .c-navbar__categories__link--collection:hover, .c-navbar__categories__link--collection:active, .c-navbar__categories__link--collection:focus {
          background: #1a87a6; }
      .c-navbar__categories__link--static {
        background: #21abd2; }
        .c-navbar__categories__link--static:hover, .c-navbar__categories__link--static:active, .c-navbar__categories__link--static:focus {
          background: #1a87a6; }
  .c-navbar__signup {
    margin: 2rem 0 1rem; }
    .c-navbar__signup__title, .c-navbar__signup__subtitle {
      padding: 0 0.75rem;
      line-height: 1.3; }
    .c-navbar__signup__title {
      margin-bottom: 0.5rem;
      color: #8a8a8a;
      font-weight: normal;
      text-transform: uppercase; }
    .c-navbar__signup__subtitle {
      font-size: 80%; }
    .c-navbar__signup__input {
      display: block;
      appearance: none !important;
      box-sizing: border-box !important;
      width: 100%;
      max-width: 100%;
      padding-left: 2.7rem;
      border: none;
      font-size: 1rem;
      line-height: 2.4rem;
      border-radius: 0;
      padding-left: 0.75rem; }
  .c-navbar__social {
    padding: 0.6rem 0.75rem 0.9rem;
    text-align: center; }
    .c-navbar__social__icon {
      display: inline-block;
      font-size: 1.56rem; }
      .c-navbar__social__icon::before {
        display: inline-block;
        content: '';
        height: 1em;
        width: 1em;
        padding: 0 1em;
        vertical-align: middle;
        opacity: 0.45882; }
      .c-navbar__social__icon:hover::before, .c-navbar__social__icon:active::before, .c-navbar__social__icon:focus::before {
        opacity: 0.8; }
      .c-navbar__social__icon span {
        display: none; }
      .c-navbar__social__icon--facebook::before {
        background: url("../svg/social/facebook.svg") center no-repeat;
        background-size: contain; }
      .c-navbar__social__icon--instagram::before {
        background: url("../svg/social/instagram.svg") center no-repeat;
        background-size: contain; }
      .c-navbar__social__icon--twitter::before {
        background: url("../svg/social/twitter.svg") center no-repeat;
        background-size: contain; }
      .c-navbar__social__icon--youtube::before {
        background: url("../svg/social/youtube.svg") center no-repeat;
        background-size: contain; }
    @media print, screen and (min-width: 50em) {
      .c-navbar__social__icon {
        font-size: 1.2rem; } }

.c-responsive-nav {
  z-index: 1000;
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px); }
  .c-responsive-nav__controls {
    padding-left: 1rem;
    background: #fff;
    box-shadow: inset 0 -1px 0 #e6e6e6; }
    .c-responsive-nav__controls::before, .c-responsive-nav__controls::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .c-responsive-nav__controls::after {
      clear: both; }
    .c-responsive-nav__controls__logo {
      float: left;
      width: 7.5rem;
      height: 2.88rem;
      background: url("/assets/magazine/svg/logo.svg") left 55% no-repeat;
      background-size: contain;
      opacity: 0.8; }
    .c-responsive-nav__controls__menu-button {
      float: right;
      padding: 0 1rem;
      border-left: 1px dotted #e6e6e6;
      color: transparent;
      line-height: 2.88rem; }
      .c-responsive-nav__controls__menu-button__icon {
        position: relative;
        display: inline-block;
        overflow: hidden;
        top: -0.0625rem;
        width: 1rem;
        height: 0.125rem;
        background: #bdbdbd;
        font-size: 0;
        vertical-align: middle;
        box-shadow: 0 0.3125rem 0 #bdbdbd, 0 -0.3125rem 0 #bdbdbd; }
      .c-responsive-nav__controls__menu-button:hover, .c-responsive-nav__controls__menu-button:active, .c-responsive-nav__controls__menu-button:focus {
        background: #e6e6e6; }
        .c-responsive-nav__controls__menu-button:hover .c-responsive-nav__controls__menu-button__icon, .c-responsive-nav__controls__menu-button:active .c-responsive-nav__controls__menu-button__icon, .c-responsive-nav__controls__menu-button:focus .c-responsive-nav__controls__menu-button__icon {
          background: #666666;
          box-shadow: 0 0.3125rem 0 #666666, 0 -0.3125rem 0 #666666; }
  .c-responsive-nav__collapsible {
    z-index: auto;
    display: none;
    height: calc(100vh - 2.88rem);
    overflow-y: scroll; }
    .is-open .c-responsive-nav__collapsible {
      display: block; }
  @media print, screen and (min-width: 50em) {
    .c-responsive-nav {
      height: auto;
      position: static;
      padding: 0;
      background: none; }
      .c-responsive-nav__controls {
        display: none; }
      .c-responsive-nav__collapsible {
        display: block;
        height: 100vh;
        margin-top: auto; } }

.c-stripe {
  border-top: 5px solid inherit;
  text-align: center; }
  .c-stripe__badge {
    display: inline-block;
    margin-top: -5px;
    padding: 0 1.4em;
    background: inherit;
    color: #fff;
    line-height: 2.3;
    text-transform: uppercase; }
  .c-stripe--vision {
    border-color: #08466c; }
    .c-stripe--vision__badge {
      background: #08466c; }
  .c-stripe--story {
    border-color: #21abd2; }
    .c-stripe--story__badge {
      background: #21abd2; }
  .c-stripe--teaching {
    border-color: #fdbf52; }
    .c-stripe--teaching__badge {
      background: #fdbf52; }
  .c-stripe--contributor {
    border-color: #ec6351; }
    .c-stripe--contributor__badge {
      background: #ec6351; }
  .c-stripe--collection {
    border-color: #21abd2; }
    .c-stripe--collection__badge {
      background: #21abd2; }
  .c-stripe--static {
    border-color: #21abd2; }
    .c-stripe--static__badge {
      background: #21abd2; }

.c-page-header {
  overflow: hidden;
  position: relative;
  background: #fff; }
  .c-page-header::before, .c-page-header::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-page-header::after {
    clear: both; }
  .c-page-header__content {
    padding: 0 1rem 2rem; }
    .c-page-header__content::after {
      content: '';
      display: block;
      width: 4rem;
      height: 0.3125rem;
      margin: 1.5rem auto 0;
      background: inherit; }
    .c-page-header__content__title {
      margin: 3rem 0 0;
      text-align: center; }
    .c-page-header__content__subtitle {
      margin-top: 0.5rem;
      color: #bfbfbf;
      font-size: 1.1rem;
      font-style: italic;
      text-align: center; }
      .c-page-header__content__subtitle a, .c-page-header__content__subtitle .c-pull-quote a > cite, .c-pull-quote .c-page-header__content__subtitle a > cite {
        color: #8a8a8a; }
        .c-page-header__content__subtitle a:hover, .c-page-header__content__subtitle .c-pull-quote a > cite:hover, .c-pull-quote .c-page-header__content__subtitle a > cite:hover, .c-page-header__content__subtitle a:active, .c-page-header__content__subtitle .c-pull-quote a > cite:active, .c-pull-quote .c-page-header__content__subtitle a > cite:active, .c-page-header__content__subtitle a:focus, .c-page-header__content__subtitle .c-pull-quote a > cite:focus, .c-pull-quote .c-page-header__content__subtitle a > cite:focus {
          color: #1e9abd; }
  .c-page-header__description {
    padding: 0 1rem;
    font-size: 80%;
    font-style: italic;
    max-width: 24rem;
    text-align: center;
    margin: 0 auto; }
    .c-page-header__description::after {
      content: '';
      display: block;
      width: 12rem;
      margin-top: 1rem;
      margin-bottom: 2rem; }
  .c-page-header--vision__content::after {
    background-color: #08466c; }
  .c-page-header--story__content::after {
    background-color: #21abd2; }
  .c-page-header--teaching__content::after {
    background-color: #fdbf52; }
  .c-page-header--contributor__content::after {
    background-color: #ec6351; }
  .c-page-header--collection__content::after {
    background-color: #21abd2; }
  .c-page-header--static__content::after {
    background-color: #21abd2; }
  @media print, screen and (min-width: 40em) {
    .c-page-header--inset {
      margin-left: 1rem;
      margin-right: 1rem; } }

.c-contributor-prefix:not(:first-of-type) {
  margin-left: 0.5em; }

.o-content {
  position: relative;
  max-width: 64rem;
  margin: 0 auto; }
  .o-content--readable {
    max-width: 50rem; }
  .o-content__body {
    background: #fff;
    padding: 2rem 1rem 11.5rem; }
    .o-content__body::before, .o-content__body::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1; }
    .o-content__body::after {
      clear: both; }
  .o-content--readable__body {
    padding-bottom: 1rem; }
  .o-content__body--inset-header {
    margin-top: -6rem;
    padding-top: 8rem;
    background: #f5f5f5; }
  .o-content__body--with-grid {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  @media print, screen and (min-width: 50em) {
    .o-content {
      margin-top: 1rem; } }
  @media print, screen and (min-width: 40em) {
    .o-content--after-banner {
      margin-top: -2rem; } }
  @media screen and (min-width: 90em) {
    .o-content--after-banner {
      margin-top: -6rem; } }

.o-result-grid {
  list-style: none;
  margin: 0; }
  .o-result-grid::before, .o-result-grid::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .o-result-grid::after {
    clear: both; }
  .o-result-grid__entry {
    float: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    @media screen and (max-width: 63.9375em) {
      .o-result-grid__entry {
        width: 50%; }
        .o-result-grid__entry:nth-child(2n + 1) {
          clear: both; } }
    @media screen and (min-width: 64em) and (max-width: 74.9375em) {
      .o-result-grid__entry {
        width: 33.33333%; }
        .o-result-grid__entry:nth-child(3n + 1) {
          clear: both; } }
    @media screen and (min-width: 75em) {
      .o-result-grid__entry {
        width: 25%; }
        .o-result-grid__entry:nth-child(4n + 1) {
          clear: both; } }

.o-result-info__title, .o-result-info__subtitle {
  display: block;
  margin: 0;
  font-size: 80%;
  line-height: 1.3; }

.o-result-info__title {
  font-weight: bold; }

.o-result-info a, .o-result-info .c-pull-quote a > cite, .c-pull-quote .o-result-info a > cite {
  color: #333; }
  .o-result-info a:hover, .o-result-info .c-pull-quote a > cite:hover, .c-pull-quote .o-result-info a > cite:hover, .o-result-info a:active, .o-result-info .c-pull-quote a > cite:active, .c-pull-quote .o-result-info a > cite:active, .o-result-info a:focus, .o-result-info .c-pull-quote a > cite:focus, .c-pull-quote .o-result-info a > cite:focus {
    color: #1e9abd; }

.c-issue-block {
  margin-bottom: 2.5rem; }
  .c-issue-block__thumb {
    width: 100%;
    margin-bottom: 0.75rem;
    padding-top: 122%;
    background: #e9e9e9 no-repeat center bottom;
    background-size: cover; }

.c-issue-link-bar {
  margin-top: -1rem;
  margin-bottom: 2rem;
  text-align: center; }
  .c-issue-link-bar__link {
    display: inline-block;
    padding: 0.8rem 1em;
    border: 1px solid #21abd2;
    color: #21abd2;
    font-size: 0.9rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 0.5rem 1rem; }
    .c-issue-link-bar__link:hover, .c-issue-link-bar__link:active, .c-issue-link-bar__link:focus {
      background: #21abd2;
      border-color: #21abd2;
      color: #f5f5f5; }

.c-post-block {
  margin-bottom: 2.5rem; }
  .c-post-block--compact {
    margin-bottom: 1rem; }
  .c-post-block--placeholder {
    width: 100%;
    padding-top: calc(50% + 5px); }
  .c-post-block__thumb {
    width: 100%;
    margin-bottom: 0.75rem;
    padding-top: 50%;
    background: #e9e9e9 no-repeat center;
    background-size: cover;
    border-top: 5px solid; }
  .c-post-block a, .c-post-block .c-pull-quote a > cite, .c-pull-quote .c-post-block a > cite {
    color: #333; }
    .c-post-block a:hover, .c-post-block .c-pull-quote a > cite:hover, .c-pull-quote .c-post-block a > cite:hover, .c-post-block a:active, .c-post-block .c-pull-quote a > cite:active, .c-pull-quote .c-post-block a > cite:active {
      color: #1e9abd; }
  .c-post-block--vision .c-post-block__thumb {
    border-color: #08466c; }
  .c-post-block--story .c-post-block__thumb {
    border-color: #21abd2; }
  .c-post-block--teaching .c-post-block__thumb {
    border-color: #fdbf52; }
  .c-post-block--contributor .c-post-block__thumb {
    border-color: #ec6351; }
  .c-post-block--collection .c-post-block__thumb {
    border-color: #21abd2; }
  .c-post-block--static .c-post-block__thumb {
    border-color: #21abd2; }

.c-contributor-block {
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  text-align: center; }
  .c-contributor-block__thumb {
    display: block;
    position: relative;
    width: 6.375rem;
    height: 6.375rem;
    margin: 0.95625rem auto 0.95625rem;
    background: #e9e9e9 no-repeat center;
    background-size: cover;
    border-radius: 50%; }
    .c-contributor-block__thumb::after {
      content: '';
      position: absolute;
      display: block;
      top: -7.5%;
      left: -7.5%;
      width: 7.33125rem;
      height: 7.33125rem;
      border: 2px solid #ec6351;
      border-radius: 50%; }
  .c-contributor-block__name, .c-contributor-block__title {
    line-height: 1.3; }
  .c-contributor-block__name {
    margin: 1.2rem 0 0;
    font-weight: bold;
    text-transform: uppercase; }
  .c-contributor-block__title {
    margin: 0.3rem 0 1rem;
    color: #bfbfbf;
    font-size: 80%;
    font-weight: bold; }
  .c-contributor-block__bio {
    font-size: 80%; }
  .c-contributor-block__more-link {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: uppercase; }
    .c-contributor-block__more-link::after {
      content: '\25b8';
      margin-left: 0.3rem; }

.c-contributor-info {
  margin: 0 0.5rem; }
  .c-contributor-info::before, .c-contributor-info::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-contributor-info::after {
    clear: both; }
  .c-contributor-info__photo {
    position: relative;
    width: 10.2rem;
    height: 10.2rem;
    margin: 1.53rem auto 1.53rem;
    background: #e9e9e9 no-repeat center;
    background-size: cover;
    border-radius: 50%;
    margin: 0 auto 3rem; }
    .c-contributor-info__photo::after {
      content: '';
      position: absolute;
      display: block;
      top: -7.5%;
      left: -7.5%;
      width: 11.73rem;
      height: 11.73rem;
      border: 2px solid #ec6351;
      border-radius: 50%; }
  .c-contributor-info__more-link {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: uppercase; }
    .c-contributor-info__more-link::after {
      content: '\25b8';
      margin-left: 0.3rem; }
  @media print, screen and (min-width: 64em) {
    .c-contributor-info__photo {
      position: relative;
      width: 11.9rem;
      height: 11.9rem;
      margin: 1.785rem auto 1.785rem;
      border-radius: 50%;
      margin: 0 0.8rem 3rem 0;
      float: right; }
      .c-contributor-info__photo::after {
        content: '';
        position: absolute;
        display: block;
        top: -7.5%;
        left: -7.5%;
        width: 13.685rem;
        height: 13.685rem;
        border: 2px solid #ec6351;
        border-radius: 50%; }
    .c-contributor-info__body {
      width: calc(100% - 17rem); } }

.c-article-banner {
  position: relative;
  width: 100%;
  padding-top: 50%;
  background: #5f5f5f no-repeat center;
  background-size: cover; }

.c-article {
  position: relative;
  background: #fff;
  max-width: 50rem;
  margin: 0 auto;
  padding-bottom: 2rem; }
  .c-article::before, .c-article::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-article::after {
    clear: both; }
  .c-article__summary {
    max-width: 28em;
    margin: 0 auto;
    padding: 0 1rem; }
    .c-article__summary::after {
      content: '';
      display: block;
      margin-bottom: 4rem; }
  .c-article__body {
    clear: both; }
  @media print, screen and (min-width: 50em) {
    .c-article {
      margin-top: 1rem; } }
  @media print, screen and (min-width: 40em) {
    .c-article--after-banner {
      margin-top: -2rem; } }
  @media screen and (min-width: 90em) {
    .c-article--after-banner {
      margin-top: -6rem; } }

.c-related-articles {
  max-width: 50rem;
  margin: auto;
  padding-top: 1rem; }
  .c-related-articles::before, .c-related-articles::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-related-articles::after {
    clear: both; }
  .c-related-articles__entry {
    display: flex;
    align-items: center;
    width: 100%;
    height: 12rem;
    margin-bottom: 1rem;
    background: #5f5f5f;
    overflow: hidden; }
    .no-flexbox .c-related-articles__entry {
      display: block; }
    .c-related-articles__entry__thumb {
      float: left;
      width: 40%;
      height: 12rem;
      background: #333 center no-repeat;
      background-size: cover; }
    .c-related-articles__entry__content {
      float: left;
      position: relative;
      width: 60%;
      max-height: 12rem;
      padding: 1rem;
      color: #fff; }
      .c-related-articles__entry__content::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.5rem;
        background: linear-gradient(to top, #5f5f5f 20%, rgba(95, 95, 95, 0)); }
      .c-related-articles__entry__content__title {
        margin-bottom: 0.3rem;
        color: inherit;
        line-height: 1.3rem; }
        .c-related-articles__entry__content__title::before {
          content: 'Related';
          display: block;
          color: #fdbf52;
          font-size: 0.6rem;
          line-height: 1.3rem;
          letter-spacing: 0.1rem;
          text-transform: uppercase; }
      .c-related-articles__entry__content__summary {
        margin: 0;
        font-size: 80%;
        line-height: 1.3rem;
        font-style: italic; }
  @media print, screen and (min-width: 64em) {
    .c-related-articles__entry {
      float: left;
      width: calc(50% - 0.5rem); }
      .c-related-articles__entry:nth-child(even) {
        margin-left: 0.5rem; }
      .c-related-articles__entry:nth-child(odd) {
        margin-right: 0.5rem; } }

.o-block--sharing {
  clear: both; }

.c-sharing {
  display: inline-block;
  border-top: 1px dotted #e6e6e6;
  padding: 1rem 1rem 0 0; }
  .c-sharing__button {
    display: block;
    float: left;
    margin: 0;
    padding: 0.5em 0.75em;
    border: 1px solid #bfbfbf;
    color: #8a8a8a;
    font-size: 80%;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase; }
    .c-sharing__button + .c-sharing__button {
      margin-left: 1rem; }
    .c-sharing__button:hover, .c-sharing__button:active, .c-sharing__button:focus {
      background: #bfbfbf;
      border-color: #bfbfbf;
      color: #fff; }
    .c-sharing__button--facebook:hover, .c-sharing__button--facebook:active, .c-sharing__button--facebook:focus {
      background-color: #3b5999;
      border-color: #3b5999; }
    .c-sharing__button--twitter:hover, .c-sharing__button--twitter:active, .c-sharing__button--twitter:focus {
      background-color: #55acee;
      border-color: #55acee; }

.o-block {
  padding: 0 1rem; }
  @media print, screen and (min-width: 64em) {
    .o-block {
      padding-right: 3rem;
      padding-left: 3rem; } }

.o-block--full-width {
  padding: 0; }

.o-block--aside {
  padding: 0; }

.c-aside {
  clear: both;
  margin: 2rem 1rem;
  padding: 2rem 1rem;
  background: #5f5f5f;
  font-size: 0.9rem;
  text-align: center;
  color: #fff;
  overflow: hidden; }
  .c-aside::before, .c-aside::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-aside::after {
    clear: both; }
  :first-child > .c-aside,
  .o-block--quote + * > .c-aside {
    margin-top: 0.5rem; }
  .c-aside__title {
    color: inherit;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0 0 1rem; }
    .c-aside__title::before {
      content: '';
      display: block;
      background: #fdbf52;
      width: 3rem;
      height: 5px;
      margin: 0 auto 1.5rem; }
  .c-aside__text > :first-child {
    margin-top: 0; }
  .c-aside__text > :last-child {
    margin-bottom: 0; }
  .c-aside__text a, .c-aside__text .c-pull-quote a > cite, .c-pull-quote .c-aside__text a > cite {
    color: #5cc7e5; }
    .c-aside__text a:hover, .c-aside__text .c-pull-quote a > cite:hover, .c-pull-quote .c-aside__text a > cite:hover, .c-aside__text a:active, .c-aside__text .c-pull-quote a > cite:active, .c-pull-quote .c-aside__text a > cite:active, .c-aside__text a:focus, .c-aside__text .c-pull-quote a > cite:focus, .c-pull-quote .c-aside__text a > cite:focus {
      color: #9edef0; }
  .c-aside__button {
    display: inline-block;
    padding: 0.8rem 1em;
    border: 1px solid #fdbf52;
    color: #fdbf52;
    font-size: 0.9rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    margin: 1rem 0.5rem 0; }
    .c-aside__button:hover, .c-aside__button:active, .c-aside__button:focus {
      background: #fdbf52;
      border-color: #fdbf52;
      color: #333; }
    .c-aside__text + .c-aside__button {
      margin-top: 1.5rem; }
  @media print, screen and (min-width: 40em) {
    .o-block--quote + * .c-aside {
      margin-top: 0; }
    .c-aside--position-left, .c-aside--position-right {
      width: 40%;
      margin: 0 auto 2rem; }
    .c-aside--position-left {
      float: left;
      margin-right: 2rem; }
    .c-aside--position-right {
      float: right;
      margin-left: 2rem; } }

.o-block--call-to-action {
  padding: 0; }

.c-call-to-action {
  clear: both;
  margin: 2rem 0;
  padding: 2.5rem 2rem;
  background: #e6e6e6;
  text-align: center; }
  .c-call-to-action__title {
    margin: 0 0 1.5rem;
    font-size: 2.4rem;
    line-height: 1;
    font-style: italic; }
    .c-call-to-action__title::before {
      content: '';
      display: block;
      background: #21abd2;
      width: 3rem;
      height: 5px;
      margin: 0 auto 1.5rem; }
  .c-call-to-action__text {
    max-width: 40rem;
    margin: 0 auto; }
  .c-call-to-action__button {
    display: inline-block;
    padding: 0.8rem 1em;
    border: 1px solid #21abd2;
    color: #21abd2;
    font-size: 0.9rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    background: #21abd2;
    color: #f5f5f5;
    margin: 1.8rem 0.5rem 0; }
    .c-call-to-action__button:hover, .c-call-to-action__button:active, .c-call-to-action__button:focus {
      background: #1a87a6;
      border-color: #1a87a6;
      color: #f5f5f5; }

.o-block--image {
  padding: 0; }

.c-image-block {
  clear: both;
  position: relative;
  width: 100%;
  margin: 2rem auto; }
  .c-image-block.is-placeholder {
    padding-top: 30%; }
  .c-image-block__image {
    width: 100%;
    margin: auto; }
  .c-image-block__caption {
    margin: 0.5rem 1rem 0;
    color: #8a8a8a;
    font-size: 80%; }
    .c-image-block__caption > p {
      margin-bottom: 0.5rem; }
      .c-image-block__caption > p:last-of-type {
        display: inline;
        margin-right: 0.3rem;
        margin-bottom: 0; }
    .c-image-block__caption--only-source {
      text-align: right; }
    .c-image-block__caption--gallery {
      padding-top: 0.5rem;
      margin: 0.7rem 0 0.5rem;
      border-top: 1px solid #e6e6e6; }
    .c-image-block__caption__source {
      font-size: 0.78em;
      letter-spacing: 0.04rem;
      text-transform: uppercase; }
      p + .c-image-block__caption__source:nth-child(n + 3) {
        display: block;
        padding-top: 0.75rem; }
  @media print, screen and (min-width: 40em) {
    .c-image-block--position-left, .c-image-block--position-right {
      width: 40%;
      margin: 0 auto 2rem; }
    .c-image-block--position-left {
      float: left;
      margin-left: 0;
      margin-right: 2rem; }
      .c-image-block--position-left__caption {
        margin-right: 0; }
    .c-image-block--position-right {
      float: right;
      margin-left: 2rem;
      margin-right: 0; }
      .c-image-block--position-right__caption {
        margin-left: 0; } }
  @media print, screen and (min-width: 64em) {
    .c-image-block__caption {
      margin-right: 3rem;
      margin-left: 3rem; }
    .c-image-block--position-left__caption {
      margin-right: 0; }
    .c-image-block--position-right__caption {
      margin-left: 0; } }
  .c-image-block--gallery {
    margin-bottom: 4rem; }
    .c-image-block--gallery__entry {
      width: calc(80% - 10%);
      margin-right: 10%; }
      .c-image-block--gallery__entry__image {
        height: 17.5rem;
        background: no-repeat center;
        background-size: contain; }
        @media print, screen and (min-width: 40em) {
          .c-image-block--gallery__entry__image {
            height: 26.25rem; } }
      .c-image-block--gallery__entry__caption {
        transition: opacity 350ms ease-in-out;
        opacity: 0; }
        .is-selected .c-image-block--gallery__entry__caption {
          opacity: 1; }
    .c-image-block--gallery:not(.flickity-enabled) .c-image-block--gallery__entry {
      margin: auto; }
      .c-image-block--gallery:not(.flickity-enabled) .c-image-block--gallery__entry:not(:first-child) {
        display: none; }

.o-block--image-quote {
  padding: 0; }

.c-image-quote {
  clear: both;
  position: relative;
  padding: 2rem 0 3rem; }
  .c-image-quote::before, .c-image-quote::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .c-image-quote::after {
    clear: both; }
  .c-image-quote__image {
    clear: both;
    width: 100%;
    padding-top: 75%;
    background: #e9e9e9 no-repeat center;
    background-size: cover; }
  .c-image-quote__pullquote {
    z-index: 1;
    clear: both;
    margin: -1rem 1rem 0;
    padding: 1.8rem;
    background: #e6e6e6;
    text-align: center;
    font-size: 1.414rem;
    line-height: 1.1;
    font-weight: 200; }
    .c-image-quote__pullquote p {
      line-height: inherit; }
      .c-image-quote__pullquote p:last-child {
        margin-bottom: 0; }
    .c-image-quote__pullquote cite {
      margin-top: 1.5em;
      font-size: 1rem;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase; }
      .c-image-quote__pullquote cite::before {
        content: none; }
  @media print, screen and (min-width: 64em) {
    .c-image-quote {
      display: flex;
      align-items: center; }
      .c-image-quote__image {
        order: 2;
        width: 50%;
        padding-top: 37.5%; }
      .c-image-quote__pullquote {
        order: 1;
        width: 52%;
        margin: 0 -2% 0 0;
        padding: 3.5rem;
        font-size: 1.7991rem; }
        .c-image-quote__pullquote cite {
          margin-bottom: -1em; }
      .no-flexbox .c-image-quote__image {
        clear: none;
        float: left;
        position: relative;
        left: 50%; }
      .no-flexbox .c-image-quote__pullquote {
        clear: none;
        float: left;
        position: relative;
        right: 50%; } }

.o-block--text h1, .o-block--text h2, .o-block--text .o-home-row__header, .o-block--text h3, .o-block--text h4, .o-block--text h5, .o-block--text h6 {
  margin-top: 1.5rem; }

.o-block--video {
  padding: 0; }

.c-video-block {
  clear: both;
  position: relative;
  width: 100%;
  margin: 2rem auto; }
  .c-video-block__video {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    padding-top: 56.25%;
    background: #000 center no-repeat;
    background-size: cover; }
    .c-video-block__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .c-video-block__caption {
    margin: 0.5rem 1rem 0;
    color: #8a8a8a;
    font-size: 80%; }
    .c-video-block__caption > p {
      margin-bottom: 0.5rem; }
      .c-video-block__caption > p:last-of-type {
        display: inline-block;
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .c-video-block--position-left, .c-video-block--position-right {
      width: 40%;
      margin: 0 auto 2rem; }
    .c-video-block--position-left {
      float: left;
      margin-left: 0;
      margin-right: 2rem; }
      .c-video-block--position-left__caption {
        margin-right: 0; }
    .c-video-block--position-right {
      float: right;
      margin-left: 2rem;
      margin-right: 0; }
      .c-video-block--position-right__caption {
        margin-left: 0; } }
  @media print, screen and (min-width: 64em) {
    .c-video-block__caption {
      margin-right: 3rem;
      margin-left: 3rem; }
    .c-video-block--position-left__caption {
      margin-right: 0; }
    .c-video-block--position-right__caption {
      margin-left: 0; } }

.is-playable:empty::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  display: block;
  width: 4rem;
  height: 4rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2rem; }

.is-playable:empty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.66667rem 0 0.66667rem 1.15333rem;
  border-color: transparent transparent transparent #f5f5f5;
  transform: translateX(0.13333rem); }

.is-playable:empty:hover::before, .is-playable:empty:focus::before, .is-playable:empty:active::before {
  background: #000; }

.is-playable:empty:hover::after, .is-playable:empty:focus::after, .is-playable:empty:active::after {
  border-left-color: #f5f5f5; }

.c-pull-quote {
  clear: both;
  padding: 1rem 0 2rem;
  max-width: 30em;
  margin: 0 auto;
  text-align: center;
  font-size: 1.414rem;
  line-height: 1.75;
  font-weight: 200; }
  .o-block--image + * > .c-pull-quote {
    padding-top: 0; }
  .c-pull-quote p {
    line-height: inherit;
    margin-bottom: 0; }
  .c-pull-quote cite {
    margin-top: 0.6rem;
    font-size: 0.707rem;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase; }
    .c-pull-quote cite::before {
      content: none; }
  @media print, screen and (min-width: 40em) {
    .o-block--image + * > .c-pull-quote {
      padding: 0.5rem 0 3rem; } }

.pagination, .pager {
  margin: 0 0 1rem;
  text-align: center; }
  .pagination::before, .pagination::after, .pager::before, .pager::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after, .pager::after {
    clear: both; }
  .pagination li, .pager li {
    display: none;
    margin-right: 0.0625rem;
    font-size: 0.875rem;
    border-radius: 0; }
    .pagination li:nth-child(-n+2), .pagination li:nth-last-child(-n+2), .pager li:nth-child(-n+2), .pager li:nth-last-child(-n+2) {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li, .pager li {
        display: inline-block; } }
  .pagination a, .pagination .c-pull-quote a > cite, .c-pull-quote .pagination a > cite, .pager a, .pager .c-pull-quote a > cite, .c-pull-quote .pager a > cite {
    display: block;
    padding: 0.1875rem 0.625rem;
    color: #333;
    border-radius: 0; }
    .pagination a:hover, .pagination .c-pull-quote a > cite:hover, .c-pull-quote .pagination a > cite:hover, .pager a:hover, .pager .c-pull-quote a > cite:hover, .c-pull-quote .pager a > cite:hover {
      background: #e6e6e6; }
  .pagination .disabled, .pagination li:nth-last-child(n+5) ~ .active::before, .pagination li:nth-last-child(n+5) ~ .active::after, .pagination .pager li:nth-last-child(n+5) ~ .active::before, .pagination .pager li:nth-last-child(n+5) ~ .active::after, .pager .disabled, .pager .pagination li:nth-last-child(n+5) ~ .active::before, .pager .pagination li:nth-last-child(n+5) ~ .active::after, .pager li:nth-last-child(n+5) ~ .active::before, .pager li:nth-last-child(n+5) ~ .active::after {
    padding: 0.1875rem 0.625rem;
    color: #bfbfbf;
    cursor: not-allowed; }
    .pagination .disabled:hover, .pagination li:nth-last-child(n+5) ~ .active:hover::before, .pagination li:nth-last-child(n+5) ~ .active:hover::after, .pagination .pager li:nth-last-child(n+5) ~ .active:hover::before, .pagination .pager li:nth-last-child(n+5) ~ .active:hover::after, .pager .disabled:hover, .pager .pagination li:nth-last-child(n+5) ~ .active:hover::before, .pager .pagination li:nth-last-child(n+5) ~ .active:hover::after, .pager li:nth-last-child(n+5) ~ .active:hover::before, .pager li:nth-last-child(n+5) ~ .active:hover::after {
      background: transparent; }
  .pagination .active, .pager .active {
    display: inline-block; }
    .pagination .active span, .pager .active span {
      display: inline-block;
      padding: 0.1875rem 0.625rem;
      background: #21abd2;
      color: #f5f5f5;
      cursor: default; }
  .pagination li:nth-last-child(n+5) ~ .active::before, .pagination li:nth-last-child(n+5) ~ .active::after, .pager li:nth-last-child(n+5) ~ .active::before, .pager li:nth-last-child(n+5) ~ .active::after {
    content: '...'; }
  .pagination .active::before, .pager .active::before {
    padding-left: 0 !important; }
  .pagination .active::after, .pager .active::after {
    padding-right: 0 !important; }
  .pagination .active:nth-child(-n+3)::before, .pagination .active:nth-last-child(-n+3)::after, .pager .active:nth-child(-n+3)::before, .pager .active:nth-last-child(-n+3)::after {
    display: none; }
  @media print, screen and (min-width: 40em) {
    .pagination .active::before, .pagination .active::after, .pager .active::before, .pager .active::after {
      content: '';
      display: none; } }

.o-lang__picker {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem; }
  .o-lang__picker__entry {
    margin-right: 0.4rem;
    color: #bfbfbf;
    font-size: 80%;
    text-transform: uppercase; }
    .o-lang__picker__entry:hover, .o-lang__picker__entry:active, .o-lang__picker__entry:focus {
      color: #1e9abd; }
    .o-lang[data-lang='en'] .o-lang__picker__entry[data-lang='en'] {
      color: #8a8a8a;
      border-bottom: 2px solid #8a8a8a; }
    .o-lang[data-lang='es'] .o-lang__picker__entry[data-lang='es'] {
      color: #8a8a8a;
      border-bottom: 2px solid #8a8a8a; }

.o-lang__content {
  display: none; }
  .o-lang[data-lang='en'] > .o-lang__content[data-lang='en'] {
    display: block; }
  .o-lang[data-lang='es'] > .o-lang__content[data-lang='es'] {
    display: block; }

.c-home-banner {
  position: relative;
  display: block;
  height: 70vw;
  max-height: 80vh; }
  .c-home-banner__image {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    background: #5f5f5f no-repeat center;
    background-size: cover; }
  @media print, screen and (min-width: 50em) {
    .c-home-banner {
      height: calc(85vw - 16.875rem); } }

.c-home-header {
  position: absolute;
  top: -5rem;
  width: calc(100% - 4rem);
  height: 5rem;
  margin-left: 2rem;
  text-shadow: 0 1px 3px rgba(51, 51, 51, 0.5), 0 -1px 1px rgba(51, 51, 51, 0.2); }
  .c-home-header__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: 1.414rem;
    font-style: italic; }
    .c-home-header__title::before {
      content: 'In This Issue';
      display: block;
      margin-bottom: 0.15rem;
      margin-left: 0.22rem;
      font-size: 0.7rem;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.1rem;
      text-transform: uppercase; }
  @media print, screen and (min-width: 40em) {
    .c-home-header {
      width: 100%;
      margin-left: 0; }
      .c-home-header__title {
        font-size: 2.34rem; } }

.o-home-row {
  padding: 0 0.5rem;
  margin-bottom: 1rem; }
  .o-home-row::before, .o-home-row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .o-home-row::after {
    clear: both; }
  .o-home-row--full-width {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0; }
  .o-home-row--inverse {
    background: #333;
    color: #fff; }
    .o-home-row--inverse a, .o-home-row--inverse .c-pull-quote a > cite, .c-pull-quote .o-home-row--inverse a > cite {
      color: #5cc7e5; }
      .o-home-row--inverse a:hover, .o-home-row--inverse .c-pull-quote a > cite:hover, .c-pull-quote .o-home-row--inverse a > cite:hover, .o-home-row--inverse a:active, .o-home-row--inverse .c-pull-quote a > cite:active, .c-pull-quote .o-home-row--inverse a > cite:active, .o-home-row--inverse a:focus, .o-home-row--inverse .c-pull-quote a > cite:focus, .c-pull-quote .o-home-row--inverse a > cite:focus {
        color: #9edef0; }
  .o-home-row--header {
    position: relative;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: justify; }
    .o-home-row--header:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 0;
      font-size: 0;
      line-height: 0; }
  .o-home-row__column {
    float: left;
    width: 100%;
    padding: 0 0.5rem; }
    .o-home-row__column--full-width {
      padding: 0; }
    @media print, screen and (min-width: 40em) {
      .t-two-column .o-home-row__column {
        width: 50%; }
        .t-two-column .o-home-row__column:nth-child(2n + 1) {
          clear: both; } }
    @media print, screen and (min-width: 40em) {
      .t-three-column .o-home-row__column {
        width: 33.33333%; }
        .t-three-column .o-home-row__column:nth-child(3n + 1) {
          clear: both; } }
  .o-home-row__header {
    display: inline-block;
    margin-top: 1rem;
    margin-bottom: 0.5rem; }
  .o-home-row__header-link {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block; }
    .o-home-row__header-link::after {
      content: '\25b8';
      margin-left: 0.3rem; }
  .o-home-row__title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase; }
    .o-home-row__title--vision {
      color: #08466c; }
    .o-home-row__title--story {
      color: #21abd2; }
    .o-home-row__title--teaching {
      color: #fdbf52; }
    .o-home-row__title--contributor {
      color: #ec6351; }
    .o-home-row__title--collection {
      color: #21abd2; }
    .o-home-row__title--static {
      color: #21abd2; }
  .o-home-row__list {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem; }
    @media print, screen and (min-width: 40em) {
      .o-home-row__list {
        margin-bottom: 0; } }
    .o-home-row__list__item {
      position: relative;
      padding-left: 1rem; }
      .o-home-row__list__item::before {
        content: '\25B8';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        line-height: 0.95rem; }
  .o-home-row__video {
    position: relative;
    height: 0;
    margin-bottom: 1rem;
    padding-bottom: 56.25%;
    overflow: hidden;
    display: block;
    margin: 0;
    background: #5f5f5f center no-repeat;
    background-size: cover; }
    .o-home-row__video iframe,
    .o-home-row__video object,
    .o-home-row__video embed,
    .o-home-row__video video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    @media print, screen and (min-width: 40em) {
      .o-home-row__video--height-match {
        min-height: 225px; } }

.c-wesleyan-block {
  padding: 2rem; }
  .c-wesleyan-block__header {
    margin: 0 0 0.75rem;
    color: inherit;
    font-size: 1.414rem;
    font-weight: 600;
    font-style: italic; }
    .c-wesleyan-block__header::before {
      content: '';
      display: block;
      background: #21abd2;
      width: 3rem;
      height: 2.5px;
      margin-bottom: 0.5rem; }
  .c-wesleyan-block__subtitle {
    margin: 0.75rem 0 0;
    color: inherit;
    font-size: 0.8rem;
    font-weight: bold; }
  .c-wesleyan-block__list {
    font-size: 0.8rem;
    list-style: none;
    margin: 0; }

.c-team-header {
  margin-bottom: 3rem;
  text-align: center; }

.guide-header, .guide-subheader {
  color: #21abd2;
  margin: 5rem 0 1.5rem;
  padding: 0 1rem 0.2em;
  border-bottom: 1px dashed rgba(33, 171, 210, 0.5);
  font-size: 1.8rem;
  line-height: 1.2; }
  @media print, screen and (min-width: 64em) {
    .guide-header, .guide-subheader {
      padding-right: 3rem;
      padding-left: 3rem; } }

.guide-header:first-of-type {
  margin-top: 1em; }

.guide-subheader {
  margin-top: 3rem;
  border-width: 1px;
  font-size: 1.2rem;
  font-weight: normal; }

.guide-drop-cap-spacing::before, .guide-drop-cap-spacing::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.guide-drop-cap-spacing::after {
  clear: both; }

.guide-drop-cap-spacing .c-drop-caps {
  display: inline-block;
  margin: 0; }
  .guide-drop-cap-spacing .c-drop-caps__letter {
    margin: 0; }

.is-placeholder {
  background: #e9e9e9 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23FFF' d='M11.9 9.6c-.4 3-.8 7-.8 8.5 0 1.9.5 2.3 1.3 2.3 1.8 0 3.1-4.3 3.7-10.9h3.5c-.3 3-.7 6.6-.7 7.9 0 2.2.4 2.9 1.3 2.9 1.6 0 2.9-3.2 3.4-8.2h-1.8l.2-2.5h5c-.4 9.9-3.3 14-7.3 14-2.7 0-3.5-2.1-3.5-4.1V19h-.1c-1.1 3.4-2.7 4.6-5 4.6-2 0-3.6-1.1-3.6-4.5 0-2.1.3-4.5.5-6.8H5l.2-2.7h6.7z'/%3E%3C/svg%3E") center no-repeat; }

/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }
