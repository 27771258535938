.o-lang {
  $root: #{&};

  &__picker {
    $offset: 1.5rem;
    margin-top: -$offset;
    margin-bottom: $offset;

    &__entry {
      $entry-color: $dark-gray;
      margin-right: 0.4rem;
      color: $medium-gray;
      font-size: $small-font-size;
      text-transform: uppercase;

      &:hover, &:active, &:focus {
        color: $anchor-color;
      }

      @each $lang in $languages {
        #{$root}[data-lang='#{$lang}'] &[data-lang='#{$lang}'] {
          color: $dark-gray;
          border-bottom: 2px solid $entry-color;
        }
      }
    }
  }

  &__content {
    display: none;

    @each $lang in $languages {
      #{$root}[data-lang='#{$lang}'] > &[data-lang='#{$lang}'] {
        display: block;
      }
    }
  }
}
