.o-text {
  &--summary {
    margin-bottom: 2rem;
    font-size: map-deep-get($header-styles, small, h4, font-size);
    line-height: 1.4;
    font-style: italic;
    text-align: center;

    em {
      font-style: normal;
    }
  }
}
