@mixin navbar-input {
  display: block;
  appearance: none !important;
  box-sizing: border-box !important;
  width: 100%;
  max-width: 100%;
  padding-left: $navbar__padding--text-only;
  border: none;
  font-size: $navbar__font__size;
  line-height: $navbar__entry__height;
  border-radius: 0;
}

.c-navbar {
  margin: 0;
  background: $navbar-background;
  list-style: none;

  @include breakpoint(medium-large) {
    padding-bottom: 1rem;
  }

  &__entry {
    position: relative;
    margin: 0;
    padding: 0;
    border-style: $brand-line__style;
    border-color: $brand-line__color;
    border-width: 1px 0;

    & + & {
      border-top-width: 0;
    }

    &--no-border {
      border: 0;
    }

    &--collapsible {
      display: none;

    }

    @include breakpoint(medium-large) {
      &--collapsible {
        display: block;
      }
    }
  }

  &__logo {
    height: 3rem;
    background: $navbar__font__color;
    margin: 1.3rem $navbar__padding--text-only 1rem;
    background: url($logo-svg) center no-repeat;
    background-size: contain;

    &__text {
      display: none;
    }
  }

  &__input {
    @include navbar-input;
  }

  &__search {
    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: $navbar__padding--horizontal;
      margin: auto;
      display: inline-block;
      width: $navbar__icon__size;
      height: $navbar__icon__size;
      background: url('/assets/magazine/svg/search.svg') center no-repeat;
      background-size: contain;
      opacity: 0.5;
    }
  }

  &__link {
    $icons: (magazine, library, contributors, about);
    display: block;
    padding-left: $navbar__padding--horizontal;
    color: $navbar__font__color;
    font-size: $navbar__font__size;
    line-height: $navbar__entry__height;
    text-transform: uppercase;

    &:hover, &:active, &:focus {
      background: $navbar__color--hover;
      color: $navbar__font__color--hover;
    }

    &__text::before {
      content: '';
      position: relative;
      display: inline-block;
      top: $navbar__icon__offset;
      width: $navbar__icon__size;
      height: $navbar__icon__size;
      margin-right: $navbar__padding--horizontal;
      background: center no-repeat;
      background-size: contain;
    }

    @each $type in $icons {
      &--#{$type} {
        &__text::before {
          background-image: url('/assets/magazine/svg/#{$type}.svg');
        }
      }
    }
  }

  &__categories {
    @include clearfix;
    width: 100%;

    &__link {
      display: block;
      float: left;
      width: percentage(1/length($categories));
      color: #fff;
      font-size: 0.85rem;
      line-height: $navbar__entry__height;
      text-align: center;
      text-transform: uppercase;

      &:hover, &:active, &:focus {
        color: #fff;
      }

      @each $type, $color in $type-colors {
        &--#{$type} {
          background: $color;

          &:hover, &:active, &:focus {
            background: darken($color, 10);
          }
        }
      }
    }
  }

  &__signup {
    margin: 2rem 0 1rem;

    &__title,
    &__subtitle {
      padding: 0 $navbar__padding--horizontal;
      line-height: $footer__line-height;
    }

    &__title {
      margin-bottom: 0.5rem;
      color: $navbar__font__color;
      font-weight: normal;
      text-transform: uppercase;
    }

    &__subtitle {
      font-size: $small-font-size;
    }

    &__input {
      @include navbar-input;
      padding-left: $navbar__padding--horizontal;
    }
  }

  &__social {
    $padding--vertical: $navbar__entry__height/4;
    padding: $padding--vertical $navbar__padding--horizontal $padding--vertical * 1.5;
    text-align: center;

    &__icon {
      display: inline-block;
      font-size: $navbar__icon__size * 1.3;

      &::before {
        display: inline-block;
        content: '';
        height: 1em;
        width: 1em;
        padding: 0 1em;
        vertical-align: middle;
        opacity: 1 - lightness($navbar__font__color)/100%;
      }

      &:hover, &:active, &:focus {
        &::before {
          opacity: 1 - lightness($navbar__font__color--hover)/100%;
        }
      }

      span {
        display: none;
      }

      @each $type in $navbar__social-icons {
        &--#{$type}::before {
          background: url('../svg/social/#{$type}.svg') center no-repeat;
          background-size: contain;
        }
      }
    }

    @include breakpoint(medium-large) {
      &__icon {
        font-size: $navbar__icon__size;
      }
    }
  }
}
