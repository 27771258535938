$blue: #08466c;
$dark-blue: #063451;
$cyan: #21abd2;
$dark-cyan: #1e9abd;
$red: #ec6351;
$dark-red: #e24632;
$yellow: #fdbf52;
$green: #82b941;
$dark-green: #67a128;

$type-colors: (
  vision: $blue,
  story: $cyan,
  teaching: $yellow,
  contributor: $red,
  collection: $cyan,
  static: $cyan
);

$block-type-colors: (
  image: $red,
  video: $red,
  aside: $green,
  call_to_action: $green,
  quote: $yellow,
  image_quote: $yellow
);
