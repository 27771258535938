.c-issue-link-bar {
  margin-top: -1rem;
  margin-bottom: 2rem;
  text-align: center;

  &__link {
    @include ghost-button();
    margin: 0 0.5rem 1rem;
  }
}
