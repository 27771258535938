@import 'foundation-sites/scss/util/util';
@import '../../../shared/sass/colors';

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.2;

$languages: ('en', 'es');

$primary-color: $cyan;
$foundation-palette: (
  primary: $primary-color,
  secondary: #777,
  success: $green,
  warning: $yellow,
  alert: $red
);

$white: #f5f5f5;
$light-gray: #e6e6e6;
$medium-gray: #bfbfbf;
$dark-gray: #8a8a8a;
$darker-gray: #777;
$black: #333;
$off-black: mix($dark-gray, $black);

$categories: (vision, story, teaching);

$body-background: $darker-gray;
$body-font-color: $black;
$header-color: $off-black;
$navbar-background: $white;
$logo-svg: '/assets/magazine/svg/logo.svg';

// Breakpoints
// - - - - - - - - - - - - - - - -

$readable-content-width: 50rem;

$breakpoints: (
  small: 0,
  medium: 640px,
  medium-large: $readable-content-width,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;

// Z-Index
// - - - - - - - - - - - - - - - -

$z-index-navbar: 1000;
$z-index-overlap: 1;

// Typography
// - - - - - - - - - - - - - - - -

$type-scale-1: 3.5rem;
$type-scale-2: 2.6rem;
$type-scale-3: 1.999rem;
$type-scale-4: 1.414rem;
$type-scale-5: 1rem;
$type-scale-6: 0.707rem;
$type-scale-7: 0.5rem;
$type-scale-8: 0.354rem;

$font-weight--medium: 600;

$body-font-family: Helvetica, Roboto, Arial, sans-serif;
$body-font-family--loaded: 'Open Sans', Helvetica, Roboto, Arial, sans-serif;

$header-font-weight: bold;
$header-color: inherit;
$header-lineheight: 1.1;
$header-margin-top: 1.5rem;
$header-margin-bottom: 0.75rem;
$header-styles: (
  small: (
    'h1': ('font-size': $type-scale-1 * 0.75),
    'h2': ('font-size': $type-scale-2 * 0.9),
    'h3': ('font-size': $type-scale-3 * 0.9),
    'h4': ('font-size': $type-scale-4),
    'h5': ('font-size': $type-scale-5),
    'h6': ('font-size': $type-scale-6)
  ),
  large: (
    'h1': ('font-size': $type-scale-1),
    'h2': ('font-size': $type-scale-2),
    'h3': ('font-size': $type-scale-3),
    'h4': ('font-size': $type-scale-4),
    'h5': ('font-size': $type-scale-5),
    'h6': ('font-size': $type-scale-6)
  )
);

$anchor-color: $dark-cyan;
$anchor-color-hover: $blue;

$blockquote-color: $body-font-color;
$blockquote-padding: 0;
$blockquote-border: none;
$cite-font-size: 1em;
$cite-color: $body-font-color;
$cite-pseudo-content: '\2014 \0020';

$drop-cap-letter-offset: (
    ('C', -0.04, 0),
    ('D', 0.02, 0),
    ('J', 0.03, -0.07),
    ('Q', -0.01, 0)
);

// Navbar
// - - - - - - - - - - - - - - - -

$navbar__width: rem-calc(270px);
$navbar__font__size: 1rem;
$navbar__entry__height: $navbar__font__size * 2.4;
$navbar__height: $navbar__entry__height * 1.2;
$navbar__padding--horizontal: 0.75rem;
$navbar__color: rgba(#000, 0.6);
$navbar__color--hover: $light-gray;
$navbar__font__color: $dark-gray;
$navbar__font__color--hover: $black;

$navbar__icon__size: 1.2rem;
$navbar__icon__offset: 0.15rem;

$navbar__padding--text-only: $navbar__icon__size + ($navbar__padding--horizontal * 2);

$navbar__social-icons: (
  facebook,
  instagram,
  twitter,
  youtube
);

// Responsive Nav
// - - - - - - - - - - - - - - - -

$responsive-nav__background: #fff;

// Components
// - - - - - - - - - - - - - - - -

$brand-line__style: dotted;
$brand-line__color: $light-gray;

$unloaded-image-color: #e9e9e9;
$unloaded-image-color--dark: $off-black;

$stripe__width: 5px;

$page-content__width: rem-calc(map-get($breakpoints, large));
$page-content__margin--top: 1rem;
$page-content__padding--top: 2rem;
$page-content__header__overlap: 6rem;
$page-header__inset: (
  medium: 1rem
);

$post-banner__overlap: (
  medium: 2rem,
  xxlarge: 6rem
);

$pre-footer__padding: 2rem;
$footer__overlap: 9.5rem;
$footer__line-height: 1.3;

// Component: Block
// - - - - - - - - - - - - - - - -

$block__width--float: 40%;
$block__background__color: #f5f5f5;
$block__border__color: #ccc;

// Component: Aside
// - - - - - - - - - - - - - - - -

$aside__key-color: $yellow;

// Component: Call To Action
// - - - - - - - - - - - - - - - -

$call-to-action__key-color: $primary-color;

// Gutters
// - - - - - - - - - - - - - - - -

$page__gutter: 1rem;
$responsive-nav__gutter: $page__gutter;
$page-header__gutter: $page__gutter;
$result-grid__gutter: $page__gutter;
$footer__gutter: $page__gutter * 2;

$block__gutter--small: $page__gutter;
$block__gutter--large: $block__gutter--small * 3;
