.guide-header, .guide-subheader {
  color: $primary-color;
  margin: 5rem 0 1.5rem;
  padding: 0 $block__gutter--small 0.2em;
  border-bottom: 1px dashed rgba($primary-color, 0.5);
  font-size: 1.8rem;
  line-height: 1.2;

  @include breakpoint(large) {
    padding-right: $block__gutter--large;
    padding-left: $block__gutter--large;
  }
}

.guide-header:first-of-type {
  margin-top: 1em;
}

.guide-subheader {
  margin-top: 3rem;
  border-width: 1px;
  font-size: 1.2rem;
  font-weight: normal;
}

.guide-drop-cap-spacing {
  @include clearfix;

  .c-drop-caps {
    display: inline-block;
    margin: 0;

    &__letter {
      margin: 0;
    }
  }
}
