.c-related-articles {
  @include clearfix;
  max-width: $readable-content-width;
  margin: auto;
  padding-top: 1rem;

  &__entry {
    $entry-height: 12rem;
    $entry-thumb-width: 40%;
    $entry-background: $off-black;
    $entry-text-color: #fff;
    $entry-line-height: 1.3rem;
    display: flex;
    align-items: center;
    width: 100%;
    height: $entry-height;
    margin-bottom: 1rem;
    background: $entry-background;
    overflow: hidden;

    .no-flexbox & {
      display: block;
    }

    &__thumb {
      float: left;
      width: $entry-thumb-width;
      height: $entry-height;
      background: $black center no-repeat;
      background-size: cover;
    }

    &__content {
      float: left;
      position: relative;
      width: 100% - $entry-thumb-width;
      max-height: $entry-height;
      padding: 1rem;
      color: $entry-text-color;

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1.5rem;
        background: linear-gradient(to top, $entry-background 20%, rgba($entry-background, 0));
      }

      &__title {
        margin-bottom: 0.3rem;
        color: inherit;
        line-height: $entry-line-height;

        &::before {
          content: 'Related';
          display: block;
          color: $yellow;
          font-size: 0.6rem;
          line-height: $entry-line-height;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
        }
      }

      &__summary {
        margin: 0;
        font-size: $small-font-size;
        line-height: $entry-line-height;
        font-style: italic;
      }
    }
  }

  @include breakpoint(large) {
    &__entry {
      float: left;
      width: calc(50% - 0.5rem);

      &:nth-child(even) {
        margin-left: 0.5rem;
      }

      &:nth-child(odd) {
        margin-right: 0.5rem;
      }
    }
  }
}
