$after-image-block: '.o-block--image + *';

.c-pull-quote {
  clear: both;
  padding: 1rem 0 2rem;
  max-width: 30em;
  margin: 0 auto;
  text-align: center;
  font-size: map-deep-get($header-styles, small, h4, font-size);
  line-height: 1.75;
  font-weight: 200;

  #{$after-image-block} > & {
    padding-top: 0;
  }

  p {
    line-height: inherit;
    margin-bottom: 0;
  }

  cite {
    margin-top: 0.6rem;
    font-size: map-deep-get($header-styles, small, h6, font-size);
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;

    &::before {
      content: none;
    }
  }

  & a > cite {
    @extend a;
  }

  @include breakpoint(medium) {
    #{$after-image-block} > & {
      padding: 0.5rem 0 3rem;
    }
  }
}
