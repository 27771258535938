@mixin fonts-loaded {
  .wf-opensans-n4-active.wf-opensans-i4-active,
  .wf-preloaded {
    @content;
  }
}

@include fonts-loaded {
  body,
  h1, h2, h3, h4, h5, h6 {
    font-family: $body-font-family--loaded;
  }
}
