.c-wesleyan-block {
  $font-size: 0.8rem;
  $spacing--vertical: 0.75rem;
  padding: $page__gutter * 2;

  &__header {
    margin: 0 0 $spacing--vertical;
    color: inherit;
    font-size: map-deep-get($header-styles, small, h4, font-size);
    font-weight: $font-weight--medium;
    font-style: italic;

    &::before {
      @include accent-stripe(small);
      margin-bottom: 0.5rem;
    }
  }

  &__subtitle {
    margin: $spacing--vertical 0 0;
    color: inherit;
    font-size: $font-size;
    font-weight: bold;
  }

  &__list {
    font-size: $font-size;
    list-style: none;
    margin: 0;
  }
}
