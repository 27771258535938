.c-responsive-nav {
  z-index: $z-index-navbar;
  position: fixed;
  width: 100%;
  background: $navbar__color;
  -webkit-backdrop-filter: blur(10px);

  &__controls {
    @include clearfix;
    padding-left: $responsive-nav__gutter;
    background: $responsive-nav__background;
    box-shadow: inset 0 -1px 0 $brand-line__color;

    &__logo {
      float: left;
      width: rem-calc(120px);
      height: $navbar__height;
      background: url($logo-svg) left 55% no-repeat;
      background-size: contain;
      opacity: 0.8;
    }

    &__menu-button {
      $menu-icon__root: #{&};
      $menu-icon__line-length: rem-calc(16px);
      $menu-icon__line-width: rem-calc(2px);
      $menu-icon__spacing: $menu-icon__line-width + ($menu-icon__line-width * 1.5);
      $menu-icon__lighten-amount: 20%;
      $menu-icon__background-color: lighten($navbar__font__color, $menu-icon__lighten-amount);
      float: right;
      padding: 0 $responsive-nav__gutter;
      border-left: 1px $brand-line__style $brand-line__color;
      color: transparent;
      line-height: $navbar__height;

      &__icon {
        position: relative;
        display: inline-block;
        overflow: hidden;
        top: -$menu-icon__line-width/2;
        width: $menu-icon__line-length;
        height: $menu-icon__line-width;
        background: $menu-icon__background-color;
        font-size: 0;
        vertical-align: middle;
        box-shadow: 0 $menu-icon__spacing 0 $menu-icon__background-color, 0 (-$menu-icon__spacing) 0 $menu-icon__background-color;
      }

      &:hover, &:active, &:focus {
        $menu-icon__background-color: lighten($navbar__font__color--hover, $menu-icon__lighten-amount);
        background: $navbar__color--hover;

        #{$menu-icon__root}__icon {
          background: $menu-icon__background-color;
          box-shadow: 0 $menu-icon__spacing 0 $menu-icon__background-color, 0 (-$menu-icon__spacing) 0 $menu-icon__background-color;
        }
      }
    }
  }

  &__collapsible {
    z-index: auto;
    display: none;
    height: calc(100vh - #{$navbar__height});
    overflow-y: scroll;

    .is-open & {
      display: block;
    }
  }

  @include breakpoint(medium-large) {
    height: auto;
    position: static;
    padding: 0;
    background: none;

    &__controls {
      display: none;
    }

    &__collapsible {
      display: block;
      height: 100vh;
      margin-top: auto;
    }
  }
}
