.o-result-info {
  &__title,
  &__subtitle {
    display: block;
    margin: 0;
    font-size: $small-font-size;
    line-height: 1.3;
  }

  &__title {
    font-weight: bold;
  }

  a {
    color: $body-font-color;

    &:hover, &:active, &:focus {
      color: $anchor-color;
    }
  }
}
