.o-result-grid {
  @include clearfix;
  list-style: none;
  margin: 0;

  &__entry {
    $padding: $result-grid__gutter/2;
    float: left;
    padding-left: $padding;
    padding-right: $padding;

    @include responsive-column(2, medium-large down);
    @include responsive-column(3, large only);
    @include responsive-column(4, xlarge);
  }
}
