.o-block--video {
  padding: 0;
}

.c-video-block {
  $video-ratio: 56.25%;
  clear: both;
  position: relative;
  width: 100%;
  margin: 2rem auto;

  &__video {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    padding-top: $video-ratio;
    background: #000 center no-repeat;
    background-size: cover;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__caption {
    margin: 0.5rem $block__gutter--small 0;
    color: $dark-gray;
    font-size: $small-font-size;

    > p {
      margin-bottom: 0.5rem;

      &:last-of-type {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint(medium) {
    &--position-left, &--position-right {
      width: $block__width--float;
      margin: 0 auto 2rem;
    }

    &--position-left {
      float: left;
      margin-left: 0;
      margin-right: 2rem;

      &__caption {
        margin-right: 0;
      }
    }

    &--position-right {
      float: right;
      margin-left: 2rem;
      margin-right: 0;

      &__caption {
        margin-left: 0;
      }
    }
  }

  @include breakpoint(large) {
    &__caption {
      margin-right: $block__gutter--large;
      margin-left: $block__gutter--large;
    }

    &--position-left {
      &__caption {
        margin-right: 0;
      }
    }

    &--position-right {
      &__caption {
        margin-left: 0;
      }
    }
  }
}

.is-playable {
  &:empty {
    $body-size: 4rem;
    $body-color: rgba(#000, 0.7);
    $body-color--hover: #000;
    $icon-size: $body-size/6;
    $icon-ratio: 1.73;
    $icon-color: $white;
    $icon-color--hover: $icon-color;

    // Play button body
    &::before {
      @include absolute-center;
      content: '';
      display: block;
      width: $body-size;
      height: $body-size;
      background: $body-color;
      border-radius: $body-size/2;
    }

    // Play button icon
    &::after {
      @include absolute-center;
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $icon-size 0 $icon-size ($icon-size * $icon-ratio);
      border-color: transparent transparent transparent $icon-color;
      transform: translateX($icon-size / 5); // Center in container
    }

    // Play button hover style
    &:hover, &:focus, &:active {
      &::before {
        background: $body-color--hover;
      }

      &::after {
        border-left-color: $icon-color--hover;
      }
    }
  }
}
