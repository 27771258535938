.o-block {
  padding: 0 $block__gutter--small;

  @include breakpoint(large) {
    padding-right: $block__gutter--large;
    padding-left: $block__gutter--large;
  }
}

.o-block--full-width {
  padding: 0;
}

@import "blocks/aside";
@import "blocks/call-to-action";
@import "blocks/image";
@import "blocks/image-quote";
@import "blocks/text";
@import "blocks/video";
