.c-stripe {
  border-top: $stripe__width solid $header-color;
  text-align: center;

  &__badge {
    display: inline-block;
    margin-top: -$stripe__width;
    padding: 0 1.4em;
    background: $header-color;
    color: #fff;
    line-height: 2.3;
    text-transform: uppercase;
  }

  @each $type, $color in $type-colors {
    &--#{$type} {
      border-color: $color;

      &__badge {
        background: $color;
      }
    }
  }
}
