.o-block--image-quote {
  padding: 0;
}

.c-image-quote {
  $image-ratio: percentage(3/4);
  @include clearfix;
  clear: both;
  position: relative;
  padding: 2rem 0 3rem;

  &__image {
    clear: both;
    width: 100%;
    padding-top: $image-ratio;
    background: $unloaded-image-color no-repeat center;
    background-size: cover;
  }

  &__pullquote {
    z-index: $z-index-overlap;
    clear: both;
    margin: -1rem $block__gutter--small 0;
    padding: 1.8rem;
    background: $light-gray;
    text-align: center;
    font-size: map-deep-get($header-styles, small, h4, font-size);
    line-height: 1.1;
    font-weight: 200;

    p {
      line-height: inherit;

      &:last-child {
        margin-bottom: 0;
      }
    }

    cite {
      margin-top: 1.5em;
      font-size: map-deep-get($header-styles, small, h5, font-size);
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;

      &::before {
        content: none;
      }
    }
  }

  @include breakpoint(large) {
    $image-width: 50%;
    $pullquote-width: 50%;
    $pullquote-overlap: 2%;
    display: flex;
    align-items: center;

    &__image {
      order: 2;
      width: $image-width;
      padding-top: $image-ratio/2;
    }

    &__pullquote {
      order: 1;
      width: $pullquote-width + $pullquote-overlap;
      margin: 0 (-$pullquote-overlap) 0 0;
      padding: 3.5rem;
      font-size: map-deep-get($header-styles, small, h3, font-size);

      cite {
        margin-bottom: -1em;
      }
    }

    .no-flexbox & {
      &__image {
        clear: none;
        float: left;
        position: relative;
        left: 100% - $image-width;
      }

      &__pullquote {
        clear: none;
        float: left;
        position: relative;
        right: 100% - $pullquote-width;
      }
    }
  }
}
