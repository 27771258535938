blockquote {
  padding: 0.5rem 0 0.5rem 1.5rem;
  font-style: italic;

  em {
    font-style: normal;
  }

  cite {
    margin-top: 1rem;
    font-style: normal;
    font-size: $small-font-size;

    &::before {
      content: '- ';
    }
  }
}

hr {
  width: 16rem;
  max-width: 75%;
  margin: 4rem auto;
  border: 0 $brand-line__style $brand-line__color;
  border-bottom-width: 2px;
}
