.o-block--sharing {
  clear: both;
}

.c-sharing {
  $gutter: 1rem;
  $border-color: $medium-gray;
  display: inline-block;
  border-top: 1px $brand-line__style $brand-line__color;
  padding: $gutter $gutter 0 0;

  &__button {
    display: block;
    float: left;
    margin: 0;
    padding: 0.5em 0.75em;
    border: 1px solid $border-color;
    color: $dark-gray;
    font-size: $small-font-size;
    font-weight: $font-weight--medium;
    text-align: center;
    text-transform: uppercase;

    & + & {
      margin-left: $gutter;
    }

    &:hover, &:active, &:focus {
      background: $border-color;
      border-color: $border-color;
      color: #fff;
    }

    &--facebook {
      &:hover, &:active, &:focus {
        background-color: #3b5999;
        border-color: #3b5999;
      }
    }

    &--twitter {
      &:hover, &:active, &:focus {
        background-color: #55acee;
        border-color: #55acee;
      }
    }
  }
}
