.c-page {
  @include clearfix;

  &__content {
    position: relative;
    margin-top: $navbar__height;
  }

  &__footer {
    $font-size: $small-font-size;
    @include clearfix;
    position: relative;
    max-width: $page-content__width - $footer__gutter;
    margin: -$footer__overlap auto 0;
    padding: 2rem $page__gutter 1rem;
    background: $black;
    font-size: $font-size;
    color: $medium-gray;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      @include accent-stripe(large);
    }

    &--after-post {
      max-width: $readable-content-width + $footer__gutter;
      margin-top: 0;
    }

    p {
      line-height: $footer__line-height;
    }

    a {
      @include dark-link;
    }

    &__column {
      overflow: hidden;
    }

    &__logo {
      height: 2.5rem;
      margin-bottom: 2rem;
      background: url($logo-svg) center no-repeat;
      background-size: contain;
    }

    &__header {
      margin-bottom: 0;
      color: inherit;
      font-size: $font-size;
      line-height: $footer__line-height;
      text-transform: uppercase;
    }
  }

  @include breakpoint(medium) {
    &__content {
      &__wrapper {
        @include clearfix;
        position: relative;
        padding: 0 $page__gutter;

        &--after-banner {
          background: linear-gradient(to bottom, darken($body-background, 5%), rgba($body-background, 0)) no-repeat;
          background-size: 100% rem-calc(400px);
        }
      }
    }

    &__footer {
      $logo-width: 40%;
      $column-width: (100% - $logo-width) / 2;
      padding-left: $footer__gutter/2;
      padding-right: $footer__gutter/2;
      line-height: $footer__line-height;

      &__column {
        float: left;
        width: $column-width;
        padding-left: $footer__gutter/2;
        padding-right: $footer__gutter/2;
      }

      &__logo {
        $margin: $footer__gutter/2;
        float: left;
        width: calc(#{$logo-width} - #{$margin});
        margin: 2rem $margin 0 0;
      }
    }
  }

  @include breakpoint(medium-large) {
    &__navbar {
      position: fixed;
      width: $navbar__width;
      height: 100vh;
      background: $navbar-background;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    &__content {
      position: relative;
      width: calc(100% - #{$navbar__width});
      min-height: 100vh;
      margin-top: 0;
      margin-left: $navbar__width;
    }
  }
}
