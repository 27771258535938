$home-row__list__indent: 1rem;

.o-home-row {
  @include clearfix;
  padding: 0 $page__gutter/2;
  margin-bottom: $page__gutter;

  &--full-width {
    margin-left: -$page__gutter;
    margin-right: -$page__gutter;
    padding: 0;
  }

  &--inverse {
    background: $black;
    color: #fff;

    a {
      @include dark-link;
    }
  }

  &--header {
    position: relative;
    margin-bottom: 0;
    padding-left: $page__gutter;
    padding-right: $page__gutter;
    text-align: justify;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 0;
      font-size: 0;
      line-height: 0;
    }
  }

  &__column {
    float: left;
    width: 100%;
    padding: 0 $page__gutter/2;

    &--full-width {
      padding: 0;
    }

    .t-two-column & {
      @include responsive-column(2, medium);
    }

    .t-three-column & {
      @include responsive-column(3);
    }
  }

  &__header {
    @extend h2;
    display: inline-block;
    margin-top: $page__gutter;
    margin-bottom: 0.5rem;
  }

  &__header-link {
    @include more-link;
    display: inline-block;
  }

  &__title {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;

    @each $type, $color in $type-colors {
      &--#{$type} {
        color: $color;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }

    &__item {
      position: relative;
      padding-left: $home-row__list__indent;

      &::before {
        content: '\25B8';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        line-height: 0.95rem;
      }
    }
  }

  &__video {
    @include responsive-embed(16 by 9);
    display: block;
    margin: 0;
    background: $off-black center no-repeat;
    background-size: cover;

    @include breakpoint(medium) {
      &--height-match {
        min-height: 225px; // To match video height with row contents
      }
    }
  }
}
