.c-home-banner {
  position: relative;
  display: block;
  height: 70vw;
  max-height: 80vh;

  &__image {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    background: $unloaded-image-color--dark no-repeat center;
    background-size: cover;
  }

  @include breakpoint(medium-large) {
    height: calc(85vw - #{$navbar__width});
  }
}
