.o-content {
  position: relative;
  max-width: $page-content__width;
  margin: 0 auto;

  &--readable {
    max-width: $readable-content-width;
  }

  &__body {
    @include clearfix;
    background: #fff;
    padding: $page-content__padding--top $page__gutter ($pre-footer__padding + $footer__overlap);
  }

  &--readable__body {
    padding-bottom: $page__gutter;
  }

  @include breakpoint(small) {
    &__body {
      &--inset-header {
        margin-top: -$page-content__header__overlap;
        padding-top: $page-content__header__overlap + $page-content__padding--top;
        background: $white;
      }

      &--with-grid {
        padding-left: $result-grid__gutter/2;
        padding-right: $result-grid__gutter/2;
      }
    }
  }

  @include breakpoint(medium-large) {
    margin-top: $page-content__margin--top;
  }

  @each $breakpoint, $overlap in $post-banner__overlap {
    @include breakpoint($breakpoint) {
      &--after-banner {
        margin-top: -$overlap;
      }
    }
  }
}
