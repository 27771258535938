.c-home-header {
  $gutter: $page__gutter * 2;
  $height: 5rem;
  position: absolute;
  top: -$height;
  width: calc(100% - #{$gutter * 2});
  height: $height;
  margin-left: $gutter;

  text-shadow: 0 1px 3px rgba($black, 0.5), 0 -1px 1px rgba($black, 0.2);

  &__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: map-deep-get($header-styles, small, h4, font-size);
    font-style: italic;

    &::before {
      content: 'In This Issue';
      display: block;
      margin-bottom: 0.15rem;
      margin-left: 0.22rem; // Visually align with title text
      font-size: 0.7rem;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
  }

  @include breakpoint(medium) {
    width: 100%;
    margin-left: 0;

    &__title {
      font-size: map-deep-get($header-styles, small, h2, font-size);
    }
  }
}
