.pagination, .pager {
  @include clearfix;
  margin: 0 0 $pagination-margin-bottom;
  text-align: center;

  li {
    display: none;
    margin-right: $pagination-item-spacing;
    font-size: $pagination-font-size;
    border-radius: $pagination-radius;

    // Always show navigation links and first/last page links
    &:nth-child(-n+2),
    &:nth-last-child(-n+2) {
      display: inline-block;
    }

    @include breakpoint(medium) {
      display: inline-block;
    }
  }

  // Page links
  a {
    display: block;
    padding: $pagination-item-padding;
    color: $pagination-item-color;
    border-radius: $global-radius;

    &:hover {
      background: $pagination-item-background-hover;
    }
  }

  .disabled {
    padding: $pagination-item-padding;
    color: $pagination-item-color-disabled;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }

  .active {
    display: inline-block;

    span {
      display: inline-block;
      padding: $pagination-item-padding;
      background: $pagination-item-background-current;
      color: $pagination-item-color-current;
      cursor: default;
    }
  }

  // Add ellipses around the active entry, but only for more than two entries
  li:nth-last-child(n+5) ~ .active {
    &::before, &::after {
      @extend .disabled;
      content: '...';
    }
  }

  // Adjust ellipsis depending position in list
  .active {
    &::before {
      padding-left: 0 !important;
    }

    &::after {
      padding-right: 0 !important;
    }

    // Hide ellipses where entries can never be truncated
    &:nth-child(-n+3)::before,
    &:nth-last-child(-n+3)::after {
      display: none;
    }

    // Hide ellipses on larger screens
    @include breakpoint(medium) {
      &::before, &::after {
        content: '';
        display: none;
      }
    }
  }
}
