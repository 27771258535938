.c-post-block {
  $root: #{&};
  margin-bottom: 2.5rem;

  &--compact {
    margin-bottom: 1rem;
  }

  &--placeholder {
    width: 100%;
    padding-top: calc(50% + #{$stripe__width});
  }

  &__thumb {
    width: 100%;
    margin-bottom: 0.75rem;
    padding-top: 50%;
    background: $unloaded-image-color no-repeat center;
    background-size: cover;
    border-top: $stripe__width solid;
  }

  a {
    color: $body-font-color;

    &:hover, &:active {
      color: $anchor-color;
    }
  }

  @each $type, $color in $type-colors {
    &--#{$type} &__thumb {
      border-color: $color;
    }
  }
}
