.c-issue-block {
  margin-bottom: 2.5rem;

  &__thumb {
    width: 100%;
    margin-bottom: 0.75rem;
    padding-top: 122%; // Standard magazine cover ratio
    background: $unloaded-image-color no-repeat center bottom;
    background-size: cover;
  }
}
