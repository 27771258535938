.o-edit-link {
  z-index: $z-index-navbar - 1;
  position: fixed;
  display: block;
  top: $navbar__height;
  right: 0;
  padding: 0.7rem 0;
  width: rem-calc(50px);
  text-align: center;
  background: $navbar__color;
  color: #fff;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;

  &:hover, &:active, &:focus {
    background: $black;
    color: #fff;
  }

  @include breakpoint(medium-large) {
    top: 0;
  }
}
