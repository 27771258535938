.c-page-header {
  @include clearfix;
  overflow: hidden;
  position: relative;
  background: #fff;

  &__content {
    padding: 0 $page-header__gutter 2rem;

    &::after {
      content: '';
      display: block;
      width: 4rem;
      height: rem-calc(5px);
      margin: 1.5rem auto 0;
      background: $header-color;
    }

    &__title {
      margin: 3rem 0 0;
      text-align: center;
    }

    &__subtitle {
      margin-top: 0.5rem;
      color: $medium-gray;
      font-size: 1.1rem;
      font-style: italic;
      text-align: center;

      a {
        color: $dark-gray;

        &:hover, &:active, &:focus {
          color: $anchor-color;
        }
      }
    }
  }

  &__description {
    $max-width: 24rem;
    padding: 0 $page-header__gutter;
    font-size: $small-font-size;
    font-style: italic;
    max-width: $max-width;
    text-align: center;
    margin: 0 auto;

    &::after {
      @extend hr;
      content: '';
      display: block;
      width: $max-width/2;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  @each $type, $color in $type-colors {
    &--#{$type} {
      &__content::after {
        background-color: $color;
      }
    }
  }

  @each $breakpoint, $inset in $page-header__inset {
    @include breakpoint($breakpoint) {
      &--inset {
        margin-left: $inset;
        margin-right: $inset;
      }
    }
  }
}
