$after-quote-block: '.o-block--quote + *';

.o-block--aside {
  padding: 0;
}

.c-aside {
  $root: #{&};
  @include clearfix;
  clear: both;
  margin: 2rem $block__gutter--small;
  padding: 2rem $block__gutter--small;
  background: $off-black;
  font-size: 0.9rem;
  text-align: center;
  color: #fff;
  overflow: hidden;

  :first-child > &,
  #{$after-quote-block} > & {
    margin-top: 0.5rem;
  }

  &__title {
    color: inherit;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0 0 1rem;

    &::before {
      @include accent-stripe(medium, $aside__key-color);
      margin: 0 auto 1.5rem;
    }
  }

  &__text {
    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    a {
      @include dark-link;
    }
  }

  &__button {
    @include ghost-button($aside__key-color, $black);
    margin: 1rem 0.5rem 0;

    #{$root}__text + & {
      margin-top: 1.5rem;
    }
  }

  @include breakpoint(medium) {
    #{$after-quote-block} & {
      margin-top: 0;
    }

    &--position-left, &--position-right {
      width: $block__width--float;
      margin: 0 auto 2rem;
    }

    &--position-left {
      float: left;
      margin-right: 2rem;
    }

    &--position-right {
      float: right;
      margin-left: 2rem;
    }
  }
}
