.c-contributor-block {
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  text-align: center;

  &__thumb {
    display: block;
    @include contributor-icon(7.5rem);
  }

  &__name, &__title {
    line-height: 1.3;
  }

  &__name {
    margin: 1.2rem 0 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__title {
    margin: 0.3rem 0 1rem;
    color: $medium-gray;
    font-size: $small-font-size;
    font-weight: bold;
  }

  &__bio {
    font-size: $small-font-size;
  }

  &__more-link {
    @include more-link;
  }
}
