.c-contributor-info {
  @include clearfix;
  margin: 0 $result-grid__gutter/2;

  &__photo {
    @include contributor-icon(12rem);
    margin: 0 auto 3rem;
  }

  &__more-link {
    @include more-link;
  }

  @include breakpoint(large) {
    $icon-width: 14rem;
    $icon-gutter: 3rem;
    $align-content: 0.8rem;

    &__photo {
      @include contributor-icon($icon-width, false);
      margin: 0 $align-content 3rem 0;
      float: right;
    }

    &__body {
      width: calc(100% - #{$icon-width + $icon-gutter});
    }
  }
}
