.o-block--call-to-action {
  padding: 0;
}

.c-call-to-action {
  clear: both;
  margin: 2rem 0;
  padding: 2.5rem $block__gutter--small * 2;
  background: $light-gray;
  text-align: center;

  &__title {
    margin: 0 0 1.5rem;
    font-size: 2.4rem;
    line-height: 1;
    font-style: italic;

    &::before {
      @include accent-stripe(medium, $call-to-action__key-color);
      margin: 0 auto 1.5rem;
    }
  }

  &__text {
    max-width: 40rem;
    margin: 0 auto;
  }

  &__button {
    @include flat-button($call-to-action__key-color);
    margin: 1.8rem 0.5rem 0;
  }
}
