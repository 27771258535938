.o-block--image {
  padding: 0;
}

.c-image-block {
  clear: both;
  position: relative;
  width: 100%;
  margin: 2rem auto;

  &.is-placeholder {
    padding-top: 30%;
  }

  &__image {
    width: 100%;
    margin: auto;
  }

  &__caption {
    $spacing: 0.5rem;
    margin: $spacing $block__gutter--small 0;
    color: $dark-gray;
    font-size: $small-font-size;

    > p {
      margin-bottom: $spacing;

      &:last-of-type {
        display: inline;
        margin-right: 0.3rem;
        margin-bottom: 0;
      }
    }

    &--only-source {
      text-align: right;
    }

    &--gallery {
      padding-top: $spacing;
      margin: ($spacing * 1.4) 0 $spacing;
      border-top: 1px solid $light-gray;
    }

    &__source {
      font-size: 0.78em;
      letter-spacing: 0.04rem;
      text-transform: uppercase;

      // Move source to a new line if the caption is multiple paragraphs long
      p + &:nth-child(n + 3) {
        display: block;
        // Increase lineheight to account for inline caption text
        padding-top: $spacing * 1.5;
      }
    }
  }

  @include breakpoint(medium) {
    &--position-left, &--position-right {
      width: $block__width--float;
      margin: 0 auto 2rem;
    }

    &--position-left {
      float: left;
      margin-left: 0;
      margin-right: 2rem;

      &__caption {
        margin-right: 0;
      }
    }

    &--position-right {
      float: right;
      margin-left: 2rem;
      margin-right: 0;

      &__caption {
        margin-left: 0;
      }
    }
  }

  @include breakpoint(large) {
    &__caption {
      margin-right: $block__gutter--large;
      margin-left: $block__gutter--large;
    }

    &--position-left {
      &__caption {
        margin-right: 0;
      }
    }

    &--position-right {
      &__caption {
        margin-left: 0;
      }
    }
  }

  &--gallery {
    $base: #{&};
    margin-bottom: 4rem;

    &__entry {
      $margin: 10%;
      width: calc(80% - #{$margin});
      margin-right: $margin;

      &__image {
        height: rem-calc(280px);
        background: no-repeat center;
        background-size: contain;

        @include breakpoint(medium) {
          height: rem-calc(420px);
        }
      }

      &__caption {
        transition: opacity 350ms ease-in-out;
        opacity: 0;

        .is-selected & {
          opacity: 1;
        }
      }
    }

    &:not(.flickity-enabled) {
      #{$base}__entry {
        margin: auto;

        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
}
